import { IconBackground } from "../components/IconBackground";
import React from 'react'
import Layout from "../components/mdxLayout";
import { TeamBlock } from "../components/TeamBlock";
import { graphql, useStaticQuery } from "gatsby";

export default function AboutUs() {
  const data = useStaticQuery(query)

  return (
    <Layout>
      <div className="bg-grape">
        <div className="relative overflow-hidden">
          <div className="text-white opacity-10">
            <IconBackground/>
          </div>

          <div className="relative pt-6 pb-16 sm:pb-18">
            <div className="mx-auto max-w-7xl px-4 sm:mt-8 sm:px-6">
              <div className="text-center">
                <h1 className="text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-6xl">
                  About Us
                </h1>
                <div className="mt-3 max-w-md mx-auto text-base text-white opacity-50 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                  <p className="my-6 text-3xl">We’re on a mission to modernise software for the health and wellbeing industry with a focus on Spa Complexes, Medispas, Clinics, and Boutiques.</p>
                  <p className="my-6 text-2xl">We’re entirely focused on our customers. Trybe is built for the industry by the industry.</p>
                  <p className="my-6 text-lg">We believe the true power of Trybe software lies in its openness and connectivity into your current system architecture such as accounting systems and PMS software.</p>
                  <p className="my-6 text-lg">The name Trybe is inspired by our core belief in team, community, connectivity, and friendship.</p>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="bg-violet-light py-16 shadow-overlay overflow-hidden">
          <div className="relative text-violet opacity-25">
            <IconBackground/>
          </div>
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <h1 className="text-center text-4xl tracking-tight font-extrabold text-purple sm:text-5xl md:text-6xl">
              Meet the team
            </h1>

            <div className="max-w-5xl mx-auto">
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-10 my-10 z-10 relative">
 
              <TeamBlock name="Ricky Daniels" title="Co-Founder" img={data.rickyPhoto}/>
              <TeamBlock name="Steve Porter" title="Co-Founder" img={data.stevePhoto}/>
              <TeamBlock name="Will Taylor-Jackson" title="Co-Founder" img={data.willPhoto}/>
              <TeamBlock name="Dan Johnson" title="Head of Engineering" img={data.danPhoto}/>
              <TeamBlock name="Jess Halladay" title="Head of Customer Experience" img={data.jessPhoto}/>
              <TeamBlock name="Emily O'Shea" title="Customer Support Team Lead" img={data.emilyPhoto}/>
              <TeamBlock name="Ben Hamrogue" title="Customer Onboarding Team Lead" img={data.benPhoto}/>
              <TeamBlock name="Khadijat Oyeleye" title="Junior Full Stack Developer" img={data.khadijatPhoto}/>
              <TeamBlock name="Sam Kingston" title="Full Stack Developer" img={data.samPhoto}/>
              <TeamBlock name="Ollie Davis" title="Business Development Executive" img={data.olliePhoto}/>
              <TeamBlock name="Colin Newman" title="Customer Onboarding and Integration Specialist" img={data.colinPhoto}/>
              <TeamBlock name="Roxane Abrahams" title="Customer Onboarding Executive" img={data.roxanePhoto}/>
              <TeamBlock name="Romain Chriqui" title="Business Development Executive" img={data.romainPhoto}/>
              <TeamBlock name="Spencer Price" title="Full Stack Developer" img={data.spencerPhoto}/>
              <TeamBlock name="Ophélie Huguet" title="Customer Support Executive" img={data.opheliePhoto}/>
              <TeamBlock name="Louna Armandoni" title="Business Development Executive" img={data.lounaPhoto}/>
              <TeamBlock name="Amy Moore" title="Marketing Manager" img={data.amyPhoto} />
              <TeamBlock name="Miriana Vanhoudt" title="Business Development Executive" img={data.mirianaPhoto} />
              <TeamBlock name="Elisa Sparaco" title="Customer Onboarding Executive" img={data.elisaPhoto} />
              <TeamBlock name="Solene Le Duc" title="Customer Onboarding Executive" img={data.solenePhoto} />
              <TeamBlock name="Maame Arthur" title="FinOps Executive" img={data.maamePhoto} />
              <TeamBlock name="Hugo" title="Head of Security" img={data.hugoPhoto}/>
              <TeamBlock name="Sooty" title="Chief Cuddle Officer" img={data.sootyPhoto}/>
              </div>
            </div>
          </div>
        </div>

      </div>
    </Layout>
  )
}

const query = graphql`
  {
    groupPhoto: imgixImage(url: "/team/group.jpg") {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 640)
    },
    rickyPhoto: imgixImage(url: "/team/ricky.png") {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 640)
    },
    stevePhoto: imgixImage(url: "/team/steve.png") {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 640)
    },
    willPhoto: imgixImage(url: "/team/will.png") {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 640)
    },
    danPhoto: imgixImage(url: "/team/dan.png") {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 640)
    },
    jessPhoto: imgixImage(url: "/team/jess.png") {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 640)
    },
    emilyPhoto: imgixImage(url: "/team/emily.png") {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 640)
    },
    benPhoto: imgixImage(url: "/team/ben.png") {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 640)
    },
    khadijatPhoto: imgixImage(url: "/team/khadijat.png") {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 640)
    },
    samPhoto: imgixImage(url: "/team/sam.png") {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 640)
    },
    olliePhoto: imgixImage(url: "/team/ollie-2.png") {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 640)
    },
    colinPhoto: imgixImage(url: "/team/colin-2.png") {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 640)
    },
    roxanePhoto: imgixImage(url: "/team/roxane-2.png") {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 640)
    },
    romainPhoto: imgixImage(url: "/team/romain-2.png") {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 640)
    },
    spencerPhoto: imgixImage(url: "/team/spencer-2.png") {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 640)
    },
    opheliePhoto: imgixImage(url: "/team/ophelie.png") {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 640)
    },
    lounaPhoto: imgixImage(url: "/team/louna.png") {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 640)
    },
    amyPhoto: imgixImage(url: "/team/amy.png") {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 640)
    },
    mirianaPhoto: imgixImage(url: "/team/miriana.png") {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 640)
    },
    elisaPhoto: imgixImage(url: "/team/elisa.png") {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 640)
    },
    solenePhoto: imgixImage(url: "/team/solene.png") {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 640)
    },
    maamePhoto: imgixImage(url: "/team/maame.png") {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 640)
    },
    hugoPhoto: imgixImage(url: "/team/hugo.png") {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 640)
    },
    sootyPhoto: imgixImage(url: "/team/sooty.png") {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 640)
    }
  }
`;
