import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export const TeamBlock = ({name, title, img}: {
  name: string,
  title: string,
  img: any
}) => (
  <div className="rounded-lg space-y-4">
    <div className="aspect-3/5 overflow-hidden rounded bg-gray-100">
      <GatsbyImage image={getImage(img)!} alt={name} />
    </div>
    <div className="text-center">
      <div className="text-lg font-medium">{name}</div>
      <div className="text-gray-500 text-sm">{title}</div>
    </div>
  </div>
)
